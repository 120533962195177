import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { ElNotification } from 'element-plus'

export function useLoginForm() {
  const store = useStore()
  const router = useRouter()

  const { handleSubmit, isSubmitting, submitCount } = useForm()

  const { value: email, errorMessage: eError, handleBlur: eBlur, meta: eMeta } = useField(
    'email',
    yup
      .string()
      .trim()
      .required('Обязательное поле')
      .email('Введите корректный email')
  )

  const { value: password, errorMessage: pError, handleBlur: pBlur, meta: pMeta } = useField(
    'password',
    yup
      .string()
      .trim()
      .required('Обязательное поле')
      .min(6, 'Не менее 6 символов')
  )

  const isTooManyAttempts = computed(() => submitCount.value >= 3)

  const onSubmit = handleSubmit(async values => {
    try {
      await store.dispatch('auth/login', values)
      router.push('/admin')
    } catch (e) {}
  })

  return {
    email,
    password,
    eError,
    eBlur,
    pError,
    pBlur,
    eMeta,
    pMeta,
    onSubmit,
    isSubmitting
  }
}