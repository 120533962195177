<template>
  <app-card form>
    <form @submit.prevent="onSubmit" novalidate>
      <h1 class="card__title" >Авторизация</h1>

      <div class="form-item input">
        <label for="email">Email</label>
        <input
          :class="['form-input', { danger: eError }]"
          v-model="email"
          type="email"
          id="email"
          @blur="eBlur"
          placeholder="Введите почту"
        >
        <small :class="['form-input-error', { active: eError }]">{{ eError }}</small>
      </div>

      <div class="form-item input">
        <label for="pass">Пароль</label>
        <input
          :class="['form-input', { danger: pError }]"
          v-model="password"
          type="password"
          id="pass"
          @blur="pBlur"
          placeholder="Введите пароль"
        >
        <small :class="['form-input-error', { active: pError }]">{{ pError }}</small>
      </div>

      <div class="form-item">
<!--        <a href="#" class="link" type="button" :disabled="isSubmitting" @click="$emit('type')">Регистрация</a>-->
        <button class="btn primary fill" type="submit" :disabled="isSubmitting">Войти</button>
      </div>
    </form>
  </app-card>
</template>

<script>
import { useLoginForm } from '../use/login-form'
import AppCard from './ui/AppCard';

export default {
  name: 'AuthForm',
  components: { AppCard },
  emits: ['type'],
  setup() {
    return {
      ...useLoginForm(),
    }
  }
}
</script>
