<template>
  <AuthForm />
<!--  <AuthForm v-if="type === 'auth'" @type="type = 'reg'"/>-->
<!--  <RegForm v-else @type="type = 'auth'" />-->
</template>

<script>
import AuthForm from "../components/AuthForm";

export default {
  name: 'Auth',
  components: { AuthForm }
}
</script>
